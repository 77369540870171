import {ILocation} from '@wix/native-components-infra/dist/es/src/types/types';
import {FilterModel, FilterType, IFilterModel, IQueryParamsFilter, ISorting} from '../types/galleryTypes';
import {PriceFilterModel} from '../models/PriceFilterModel';

export enum DefaultQueryParamKeys {
  Sort = 'sort',
  Page = 'page',
}

export class QueryParamsService {
  private readonly location: ILocation;
  private readonly currentUrlSearchParams: {paramTitle: string; paramValue: string}[];

  constructor(location) {
    this.location = location;
    this.currentUrlSearchParams = [];
  }

  public readonly getFiltersFromQueryParams = (filterModels: FilterModel[]): IQueryParamsFilter[] => {
    const searchParamsDecoded = this.getSearchParamsDecoded(this.location.query);
    const activeFilters = [];

    filterModels.forEach(filter => {
      if (searchParamsDecoded[filter.title]) {
        switch (filter.filterType) {
          case FilterType.LIST_OPTION:
          case FilterType.CUSTOM_COLLECTION:
          case FilterType.COLOR_OPTION:
          case FilterType.COLLECTION: {
            activeFilters.push({
              key: filter.title,
              value: searchParamsDecoded[filter.title],
              filterId: filter.filterId,
            });

            break;
          }
          case FilterType.PRICE: {
            const priceValue = searchParamsDecoded[filter.title].split('-');
            activeFilters.push({
              key: filter.title,
              value: `${priceValue[0]}|${priceValue[1]}`,
              filterId: filter.filterId,
            });
          }
        }
        this.setAndCurrentUpdateQueryParams(filter.title, searchParamsDecoded[filter.title]);
      }
    });

    return activeFilters.length > 0 ? activeFilters : null;
  };

  public readonly getQueryParam = (paramTitle: string): string => {
    const queryParams = this.location.query;
    this.setAndCurrentUpdateQueryParams(paramTitle, queryParams[paramTitle]);
    return queryParams[paramTitle];
  };

  public readonly updateQueryParamsByPage = (offset: number, productPerPage: number): void => {
    const page = (offset + productPerPage) / productPerPage;
    this.setAndUpdateQueryParams(DefaultQueryParamKeys.Page, page.toString());
  };

  public readonly updateQueryParamsBySort = (selectedSort: ISorting) => {
    if (selectedSort.id === 'default') {
      this.removeQueryParam(DefaultQueryParamKeys.Sort);
    } else {
      this.setAndUpdateQueryParams(DefaultQueryParamKeys.Sort, selectedSort.id);
    }
  };

  public readonly clearAllQueryParamsFilters = (paramTitles: string[]) => {
    this.location.queryParams.remove(paramTitles);
    paramTitles.forEach(title => {
      const index = this.currentUrlSearchParams.findIndex(param => param.paramTitle === title);
      if (index !== -1) {
        this.currentUrlSearchParams.splice(index, 1);
      }
    });
  };

  public readonly updateQueryParamsByFilters = (
    filterId: number,
    filterModels: FilterModel[],
    mainCollectionId: string
  ) => {
    const filterModel = filterModels.find(fm => fm.filterId === filterId) as IFilterModel;
    let activeFilterOption: string = '';

    switch (filterModels[filterId].filterType) {
      case FilterType.COLLECTION: {
        if (filterModel.activeOptions === mainCollectionId) {
          activeFilterOption = `All`;
        } else {
          const filterOption = filterModel.options.find(option => option.key === filterModel.activeOptions);
          activeFilterOption = filterOption.value;
        }
        break;
      }
      case FilterType.LIST_OPTION:
      case FilterType.CUSTOM_COLLECTION:
      case FilterType.COLOR_OPTION: {
        const filterOptions = filterModel.options.filter(option => filterModel.activeOptions.indexOf(option.key) > -1);

        if (filterOptions.length === 1) {
          activeFilterOption = filterOptions[0].value;
        } else if (filterOptions.length === 0) {
          this.removeQueryParam(filterModel.title);
        } else {
          activeFilterOption = filterOptions.map(filter => filter.value).join('|');
        }

        break;
      }
      case FilterType.PRICE: {
        activeFilterOption = `${(filterModel.activeOptions as PriceFilterModel).minPrice}-${
          (filterModel.activeOptions as PriceFilterModel).maxPrice
        }`;
      }
    }
    if (activeFilterOption) {
      this.setAndUpdateQueryParams(filterModel.title, activeFilterOption);
    }
  };

  private readonly getSearchParamsDecoded = (searchParams: {
    [paramKey: string]: string;
  }): {[paramKey: string]: string} => {
    return Object.keys(searchParams).reduce((res, key) => {
      return {...res, [decodeURIComponent(key)]: decodeURIComponent(searchParams[key])};
    }, {});
  };

  private readonly removeQueryParam = paramTitle => {
    const index = this.currentUrlSearchParams.findIndex(param => param.paramTitle === paramTitle);

    this.currentUrlSearchParams.splice(index, 1);
    this.location.queryParams.remove([paramTitle]);
  };

  private readonly setAndCurrentUpdateQueryParams = (paramTitle: string, paramValue: string) => {
    const index = this.currentUrlSearchParams.findIndex(param => param.paramTitle === paramTitle);

    if (index === -1) {
      this.currentUrlSearchParams.push({paramTitle, paramValue});
    } else {
      this.currentUrlSearchParams[index] = {paramTitle, paramValue};
    }
  };

  private readonly setAndUpdateQueryParams = (paramTitle: string, paramValue: string) => {
    this.setAndCurrentUpdateQueryParams(paramTitle, paramValue);

    this.location.queryParams.add({[paramTitle]: paramValue});
  };
}
